<template>
  <div>
    <el-form
      :model="saveData"
      :rules="rules"
      ref="saveData"
      label-width="150px"
      class="demo-ruleForm"
      style="margin-top: 10px; margin-left: 3%"
      @submit.native.prevent
    >
      <el-form-item :label="$t('ContentManage.DocumentManagement.NewFolder')+'：'" prop="name">
        <el-input
          size="small"
          v-model="saveData.name"
          style="width: 340px; margin-right: 10px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="submitForm('saveData')"
          >{{$t('public.Save')}}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    let validcodeName = (rule, value, callback) => {
      let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('ContentManage.DocumentManagement.NotSupported')));
      } else {
        callback();
      }
    };
    return {
      flag: 3,
      rules: {
        name: [
          { required: true, message: this.$t('ContentManage.DocumentManagement.Rules.FolderName'), trigger: "blur" },
          { validator: validcodeName, trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: this.$t('ContentManage.DocumentManagement.Rules.NameLength'),
            trigger: "blur",
          },
        ],
      },
      saveData: {
        name: "",
        docType: "folder",
        parentId: "0",
        id: "",
        isFolder: 1,
        docSize: "-1",
        docCategoryId: "-1",
        isBurnAfterRead: "-1",
        validType: "-1",
      },
    };
  },
  created() {},
  mounted() {
    if (this.dictData) {
      this.saveData.name = this.dictData.name;
      this.saveData.id = this.dictData.id;
      this.saveData.parentId = this.dictData.parentId;
    }
  },
  methods: {
    //获取回显数据
    async getFolShowList() {
      var parma = { manage: 1, action: "edit", id: this.dictData.id };
      const res = await this.$axios.post(
        "/api/document/selectOne.do",
        parma,
        true
      );
      this.saveData.name = res.data.name;
      this.saveData.action = "edit";
      this.saveData.id = this.dictData.id;
      this.saveData.parentId = this.dictData.parentId;
    },
    //保存
    submitForm() {
      this.$refs.saveData.validate(async (valid) => {
        if (valid) {
          var parma = {
            name: this.saveData.name,
            docType: "folder",
            parentId: this.dictData.parentId,
            ids: [this.saveData.id],
            isFolder: 1,
            docSize: "",
            docCategoryId: "",
            isBurnAfterRead: "",
            validType: "",
          };
          const res = await this.$axios.post(
            "/httpServe/document/update",
            parma,
            true
          );
          this.flag = 1;
          this.$emit("editFolderSave", res);
        } else {
          console.log("error");
        }
      });
    },
    // 只能输入汉字、英文、数字
    btKeyDown(e) {
      e.target.value = e.target.value.replace(
        /[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,
        ""
      );
    },
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
  },
};
</script>

<style lang='scss' scoped>
</style>