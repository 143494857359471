<template>
  <div>
    <el-row style="margin:10px 10px 0px 10px;">
      <el-breadcrumb
        id="myDiv"
        separator-class="el-icon-arrow-right"
        style="color: #333333; font-size: 12px"
      >
        <el-breadcrumb-item @click.native="goHome" style="cursor: pointer">{{
          $t("ContentManage.DocumentManagement.DocumentNav")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item
          v-for="(item, index) in menuName"
          :key="index"
          style="cursor: pointer"
          @click.native="changeBread(item.id, index)"
          >{{ item.name }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-row>
    <el-row class="content" v-if="flag == 1">
      <el-row :gutter="4" class="elRow" style="display: flex">
        <el-col :span="2">
          <el-select
            style="width: 100%"
            size="small"
            v-model="searchData.valid"
            @keyup.enter.native="search"
            :placeholder="
              $t('ContentManage.DocumentManagement.SelectPlaceholder')
            "
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="
                $t(
                  `ContentManage.DocumentManagement.SelectOptions.${item.label}`
                )
              "
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-input
            v-model="searchData.name"
            :placeholder="
              $t('ContentManage.DocumentManagement.InputPlaceholder')
            "
            size="small"
            @keyup.enter.native="search"
            @input="(e) => (searchData.name = validForbid(e))"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="small" @click="search">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="4" class="elRow">
        <el-col :span="24">
          <el-button-group>
            <el-button
              type="primary"
              @click="addnewDoc"
              class="determineBtn"
              size="small"
              >{{ $t("ContentManage.DocumentManagement.NewDoc") }}</el-button
            >
            <el-button
              type="primary"
              @click="addnewFolder"
              class="determineBtn"
              size="small"
              >{{ $t("ContentManage.DocumentManagement.NewFolder") }}</el-button
            >
            <el-button
              @click="transfers(delarr)"
              :type="typeInfo"
              :disabled="!hasSeleted"
              size="small"
              >{{ $t("public.Transfer") }}</el-button
            >
            <el-button
              @click="distribution1"
              :type="typeInfo"
              :disabled="!hasSeleted"
              size="small"
              >{{ $t("public.Distribution") }}</el-button
            >
            <el-button
              style="border-radius: 0px 3px 3px 0px; margin-left: 1.3px"
              @click="delArr(delarr)"
              :type="typeInfo"
              :disabled="!hasSeleted"
              size="small"
              >{{ $t("public.Delete") }}</el-button
            >
          </el-button-group>
        </el-col>
      </el-row>
      <!-- 列表 -->
      <el-row :gutter="4" class="elRow" style="position: relative">
        <el-table
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '30px' }"
          ref="tableData"
          :data="tableData"
          :row-key="getRowKeys"
          lazy
          size="mini"
          stripe
          class="tableClass"
          tooltip-effect="dark"
          style="width: 100%"
          :default-sort="{ prop: 'lastUpdateTime', order: 'descending' }"
          @selection-change="delobj"
          @sort-change="handleSortChange"
        >
          <el-table-column
            type="selection"
            align="center"
            width="60"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('ContentManage.DocumentManagement.Name')"
            width="180"
            sortable="custom"
            style="line-height: 30px"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <template v-if="row.docType == 'folder'">
                <img src="@/images/file/folder.png" alt="" class="nameimg" />
                <span
                  style="color: #d38a08; cursor: pointer"
                  @click="newDoc(row)"
                  >{{ row.name }}</span
                >
              </template>
              <template v-else>
                <template>
                  <img
                    v-if="row.docType == 'txt'"
                    src="@/images/file/txt.png"
                    alt=""
                    class="nameimg"
                  />
                  <img
                    v-if="row.docType == 'xls' || row.docType == 'xlsx'"
                    src="@/images/file/xls.png"
                    alt=""
                    class="nameimg"
                  />
                  <img
                    v-if="row.docType == 'docx' || row.docType == 'doc'"
                    src="@/images/file/docx.png"
                    alt=""
                    class="nameimg"
                  />
                  <img
                    v-if="row.docType == 'png'"
                    src="@/images/file/png.png"
                    alt=""
                    class="nameimg"
                  />
                  <img
                    v-if="row.docType == 'jpg' || row.docType == 'jpeg'"
                    src="@/images/file/jpg.png"
                    alt=""
                    class="nameimg"
                  />
                  <img
                    v-if="row.docType == 'ppt' || row.docType == 'pptx'"
                    src="@/images/file/ppt.png"
                    alt=""
                    class="nameimg"
                  />
                  <img
                    v-if="row.docType == 'pdf'"
                    src="@/images/file/pdf.png"
                    alt=""
                    class="nameimg"
                  />
                </template>
                <span>{{ row.name }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="groupFullName"
            :label="$t('public.Section')"
            sortable="custom"
            width="110"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">
                {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="docType"
            :label="$t('ContentManage.DocumentManagement.DocType')"
            sortable="custom"
            width="110"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.docType == 'folder'"> -- </span>
              <span v-else>{{ row.docType }}</span>
            </template>
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="docSize"
            :label="$t('public.Size')"
            sortable="custom"
            width="110"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.docSizeStr == '0' || !row.docSizeStr">--</span>
              <span v-else>{{ row.docSizeStr }}</span>
            </template>
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="categoryFullName"
            :label="$t('ContentManage.DocumentManagement.CategoryFullName')"
            sortable="custom"
            width="110"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.categoryFullName">{{
                row.categoryFullName
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="validType"
            :label="$t('ContentManage.DocumentManagement.Indate')"
            sortable="custom"
            width="110"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span
                style="color: green"
                v-if="row.valid == 1 && row.validType == 1"
                :title="$t('ContentManage.DocumentManagement.PermanentlyValid')"
                >{{ $t("public.Normal") }}</span
              >
              <span
                style="color: green"
                v-if="row.valid == 1 && row.validType == 2"
                :title="
                  $t('ContentManage.DocumentManagement.Custom') +
                  '：' +
                  row.startTimeStr +
                  '~' +
                  row.endTimeStr
                "
                >{{ $t("public.Normal") }}</span
              >
              <span
                style="color: red"
                v-else-if="row.valid == 2"
                :title="
                  $t('ContentManage.DocumentManagement.Custom') +
                  '：' +
                  row.startTimeStr +
                  '~' +
                  row.endTimeStr
                "
                >{{ $t("ContentManage.DocumentManagement.Expired") }}</span
              >
              <span v-else-if="row.valid == 0">--</span>
            </template>
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="userId"
            :label="$t('ContentManage.DocumentManagement.Creator')"
            sortable="custom"
            width="110"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              {{ row.userName }}
            </template>
          </el-table-column>
          <!-- 原有功能，误删！ -->
          <!-- <el-table-column
            prop="readAndAllocated"
            label="阅读/分配"
            width="110"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <template v-if="row.readAndAllocated == '0/0'">
                <span>{{ row.readAndAllocated }}</span>
              </template>
              <template v-else>
                <span
                  style="color: #d38a08; cursor: pointer"
                  @click="nameDetail(row)"
                  >{{ row.readAndAllocated }}</span
                >
              </template>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="lastUpdateTime"
            :label="$t('public.ModificationTime')"
            width="180"
            :show-overflow-tooltip="true"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <span class="action_icon">
                <img
                  @click="nameDetail(row)"
                  src="@/assets/icon_see.png"
                  style="width: 15px; height: 15px; cursor: pointer"
                  :title="
                    $t('ContentManage.DocumentManagement.Read') +
                    '/' +
                    $t('public.Distribution')
                  "
                />
              </span>
              <span class="action_icon">
                <img
                  @click="distribution(row)"
                  src="@/images/icon_delivery.png"
                  :title="$t('public.Distribution')"
                  alt=""
                  style="width: 15px; height: 15px; cursor: pointer"
                />
              </span>
              <span class="action_icon">
                <img
                  @click="download(row)"
                  v-if="row.docType != 'folder'"
                  src="@/images/icon_download.png"
                  :title="$t('public.Download')"
                  alt=""
                  style="width: 15px; height: 15px; cursor: pointer"
                />
              </span>
               
              <span class="action_icon">
                <img
                  @click="edit(row)"
                  v-if="row.docType != 'folder'"
                  src="@/assets/icon_write.png"
                  :title="$t('public.Editor')"
                  style="width: 15px; height: 15px; cursor: pointer"
                />
              </span>
              <span class="action_icon">
                <img
                  @click="del(row.id)"
                  src="@/assets/icon_del.png"
                  :title="$t('public.Delete')"
                  style="width: 15px; height: 15px; cursor: pointer"
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          :page="searchData.currentPage"
          :limit="searchData.pageSize"
          :total="total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </el-row>
    </el-row>

    <!-- 新增文档 -->
    <addDoc
      v-if="flag == 2"
      @addSaveDoc="addSaveDoc"
      :dictData="editDoc"
    ></addDoc>
    <!-- 编辑文档 -->
    <editDoc v-if="flag == 4" :dictData="editDoc" @editSaveDoc="editSaveDoc" />
    <!-- 新增文件夹 -->
    <add-folder
      v-if="flag == 3"
      :dictData="editFolder"
      @addFolderSave="addFolderSave"
    ></add-folder>
    <!-- 编辑文件夹 -->
    <editFolder
      :dictData="editFolder"
      @editFolderSave="editFolderSave"
      v-if="flag == 5"
    />
    <!-- 文档阅读情况 -->
    <Eldialog
      @handleClose="handleClose"
      :title="$t('ContentManage.DocumentManagement.TitleInfo')"
      :dialogVisible="installFlag"
      :num="'469px'"
      :width="'900px'"
      v-if="installFlag"
    >
      <readVisible :dictData="readVisibleData" ref="installRfe"></readVisible>
    </Eldialog>
    <!-- 转移 -->
    <Eldialog
      v-if="transferFlag"
      @handleClose="handleClose"
      :title="$t('ContentManage.DocumentManagement.SelectFolder')"
      :dialogVisible="transferFlag"
      :width="'420px'"
      class="dialog"
    >
      <div class="zTreeSelect">
        <el-tree
          :data="dataArray"
          node-key="id"
          ref="tree"
          :props="defaultProps2"
          :current-node-key="currentNodeKey"
          :highlight-current="true"
          :load="loadNode"
          :default-expanded-keys="[0]"
          lazy
        >
          <span slot-scope="{ node, data }">
            <el-radio v-model="transFrom.destId" :label="data.id">
              <i class="el-icon-folder-opened"></i>
              {{ node.label }}
            </el-radio>
          </span>
        </el-tree>
      </div>
      <div style="margin-left: 55px">
        {{ $t("ContentManage.DocumentManagement.SameFolder")
        }}<el-radio v-model="transFrom.coverType" label="1">{{
          $t("ContentManage.DocumentManagement.Cover")
        }}</el-radio>
        <el-radio v-model="transFrom.coverType" label="2">{{
          $t("ContentManage.DocumentManagement.Rename")
        }}</el-radio>
      </div>
      <div class="footertrans">
        <el-button
          v-if="determineBtn"
          size="mini"
          type="primary"
          class="btn-determine"
          @click="transFile"
          >{{ $t("public.Transfer") }}
        </el-button>
        <el-button
          v-if="cancelBtn"
          size="mini"
          type="info"
          class="btn-cancel"
          @click="handleClose"
          >{{ $t("ContentManage.DocumentManagement.Close") }}</el-button
        >
      </div>
    </Eldialog>
    <!-- 保存成功的弹框 -->
    <dialog-info
      :dialogInfo="Successdialog"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="seccess"
      :width="'240px'"
    />
  </div>
</template>

<script>
const Base64 = require("js-base64").Base64;
import pagination from "@/components/pagination/page";
import naviGation from "@/components/hearder/index";
import dialogInfo from "@/components/promptMessage/index";
import Eldialog from "@/components/elDialog/index.vue";
import addDoc from "./components/addDoc.vue";
import editDoc from "./components/editDoc.vue";
import addFolder from "./components/addFolder.vue";
import editFolder from "./components/editFolder.vue";
import readVisible from "./components/readVisible.vue";
export default {
  components: {
    pagination,
    naviGation,
    dialogInfo,
    Eldialog,
    addDoc,
    editDoc,
    addFolder,
    editFolder,
    readVisible,
  },
  data() {
    return {
      typeInfo: "info", // 删除按钮默认灰色
      tabID: "",
      seccess: "",
      groupFullNameShowByIndex: "", //用户部门截取的下标
      parentId: "0",
      parent: "0",
      Successdialog: false, //控制弹出
      installFlag: false,
      transferFlag: false,
      flagStatus: true,
      determineBtn: true,
      cancelBtn: true,
      flag: 1,
      currentPage: 1, //当前页
      pageSize: 10, //每页显示条数
      total: 0,
      currentNodeKey: 0,
      imgUrl: vueConfig.jqUrl.slice(0, vueConfig.jqUrl.length - 1),
      delarr: [], //删除
      tableData: [],
      menuName: [],
      listIds: [],
      MenuData: [{ name: "", children: {} }],
      dataArray: [
        {
          id: 0,
          name: this.$t("ContentManage.DocumentManagement.DocumentNav"),
          children: [],
          hasChild: true,
        },
      ],
      options1: [
        {
          value: "",
          label: "有效期",
        },
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "已过期",
        },
      ],
      readVisibleData: {},
      searchData: {
        currentPage: 1,
        pageSize: 10,
        parentId: "0",
        name: "",
        orderColume: "d.isFolder ASC, d.lastUpdateTime",
        orderRule: "DESC",
        valid: "",
      },
      defaultProps2: {
        label: "name",
        children: "children",
        isLeaf: "hasChild",
      },
      transFrom: { srcIds: [], destId: 0, coverType: "1" },
      editFolder: {
        parentId: "",
        id: "",
      },
      editDoc: {
        parentId: "",
        id: "",
      },
    };
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length;
    },
  },
  mounted() {
    if (document.styleSheets[0].cssRules.length >= 2) {
      document.styleSheets[0].deleteRule(0);
    }
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.getDataList();
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },

    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.searchData.currentPage = page;
      this.searchData.pageSize = limit;
      // 调用查询方法
      this.getDataList();
    },
    // 列表
    async getDataList() {
      const res = await this.$axios.post(
        "/httpServe/document/getDataInfo",
        this.searchData,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    // 跳转面包屑文档管理
    async goHome() {
      var parma = {
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        parentId: "0",
        name: "",
        orderColume: "d.isFolder ASC, d.lastUpdateTime",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/document/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
      this.searchData.parentId = "0";
      this.menuName = [];
      this.parent = 0;
    },

    // 面包屑跳转
    async changeBread(id, index) {
      var parma = {
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        parentId: String(id),
        name: "",
        valid: "",
        orderColume: "d.isFolder ASC, d.lastUpdateTime",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/document/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
      let menuArr = this.menuName;
      this.menuName = menuArr.slice(0, index + 1);
    },
    // 文件夹列表
    async newDoc(val) {
      this.searchData.name = "";
      this.parent = val.id;
      let data = [];
      let obj = { name: val.name, id: val.id };
      data.push(obj);
      this.menuName = this.menuName.concat(data);
      this.parentId = val.id;
      this.searchData.parentId = String(val.id);
      var parma = {
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        parentId: this.searchData.parentId,
        name: this.searchData.name,
        orderColume: this.searchData.orderColume,
        orderRule: this.searchData.orderRule,
        valid: this.searchData.valid,
      };
      const res = await this.$axios.post(
        "/httpServe/document/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    // 查询
    async search() {
      if (this.searchData.name.length > 0) {
        this.searchData.currentPage = 1;
      }
      if (this.searchData.parentId > 0) {
        var parma = {
          currentPage: this.searchData.currentPage,
          pageSize: this.searchData.pageSize,
          parentId: this.searchData.parentId,
          name: this.searchData.name,
          valid: this.searchData.valid,
          orderColume: this.searchData.orderColume,
          orderRule: this.searchData.orderRule,
        };
      } else {
        // 搜索查询条件为“有效期和输入名称为空”时parentId传0;其他传-1
        if (this.searchData.valid == "" && this.searchData.name == "") {
          this.searchData.parentId = "0";
        } else {
          this.searchData.parentId = "-1";
          this.searchData.currentPage = 1;
        }
        var parma = {
          currentPage: this.searchData.currentPage,
          pageSize: this.searchData.pageSize,
          parentId: this.searchData.parentId,
          name: this.searchData.name,
          valid: this.searchData.valid,
          orderColume: this.searchData.orderColume,
          orderRule: this.searchData.orderRule,
        };
      }
      const res = await this.$axios.post(
        "/httpServe/document/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    // 阅读/分配
    async nameDetail(val) {
      this.tabID = val.id;
      this.readVisibleData.tabID = this.tabID;
      this.installFlag = true;
    },
    // 编辑
    async edit(val) {
      if (val.docType == "folder") {
        this.editFolder = val;
        this.editFolder.parentId = this.parentId;
        this.flag = 5;
      } else {
        this.editDoc = val;
        this.editDoc.parent = this.parentId;
        this.flag = 4;
        document.styleSheets[0].insertRule("#myDiv {pointer-events: none;}", 0);
      }
    },
    // 下载
    async download(val) {
      var parma = {
        type: "document",
        path: val.path,
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        parma,
        true
      );
      if (res.data == null) {
        this.$message({
          message: this.$t("ContentManage.DocumentManagement.FileIsEmpty"),
          type: "error",
          offset: 100,
        });
        return false;
      } else {
        let url = Base64.decode(res.data);
        url = this.imgUrl + url;
        location.href = url;
      }
    },
    // 删除
    delobj(obj) {
      this.delarr = obj;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
    },
    delArr(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      // 请求接口
      var parma = {
        ids: ids,
        deleteAll: 2,
      };
      this.$confirm(
        this.$t("ContentManage.DocumentManagement.CheckDelFile"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/document/delete",
            parma,
            true
          );
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.tableData.clearSelection();
          this.searchData.currentPage = 1;
          this.getDataList();
        })
        .catch(() => {
          return false;
        });
    },
    del(id) {
      // 请求接口
      var parma = { ids: [id], deleteAll: 2 };
      this.$confirm(
        this.$t("ContentManage.DocumentManagement.CheckDelDoc"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/document/delete",
            parma,
            true
          );
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.tableData.clearSelection();
          this.searchData.currentPage = 1;
          this.getDataList();
        })
        .catch(() => {
          return false;
        });
    },
    // 弹框
    handleClose() {
      this.Successdialog = false; //提示消息  关闭
      this.installFlag = false;
      this.transferFlag = false;
      this.outerVisible = false;
      this.$nextTick(() => {
        this.flagStatus = true;
      });
      this.$refs.installRfe._data.input = "";
    },
    determine() {
      this.Successdialog = false;
    },
    // 新增文档
    addnewDoc() {
      this.flag = 2;
      this.editDoc.parentId = this.parentId;
      document.styleSheets[0].insertRule("#myDiv {pointer-events: none;}", 0);
    },
    // 新增文档成功
    addSaveDoc(val) {
      if (val.errorCode == 771) {
        this.$message({
          message: this.$t("ContentManage.DocumentManagement.ErrorDoc"),
          type: "error",
          offset: 100,
        });
      } else if (val.data == 0) {
        this.$message({
          message: this.$t("ContentManage.DocumentManagement.SameDoc"),
          type: "error",
          offset: 100,
        });
      } else {
        this.flag = 1;
        this.searchData.name = "";
        document.styleSheets[0].deleteRule(0);
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.getDataList();
      }
    },
    // 编辑文档成功
    editSaveDoc(val) {
      if (val.errorCode == 771) {
        this.$message({
          message: this.$t("ContentManage.DocumentManagement.ErrorDoc"),
          type: "error",
          offset: 100,
        });
      } else if (val.data == 0) {
        this.$message({
          message: this.$t("ContentManage.DocumentManagement.SameDoc"),
          type: "error",
          offset: 100,
        });
      } else {
        this.flag = 1;
        this.searchData.name = "";
        document.styleSheets[0].deleteRule(0);
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.getDataList();
      }
    },
    // 新增文件夹
    addnewFolder() {
      this.flag = 3;
      this.editFolder.parentId = this.parentId;
      document.styleSheets[0].insertRule("#myDiv {pointer-events: none;}", 0);
    },
    // 新增文件夹
    async addFolderSave(val) {
      if (val.code == 786) {
        this.$message({
          message: this.$t("ContentManage.DocumentManagement.SameDoc"),
          type: "error",
          offset: 100,
        });
      } else if (val.code == 101) {
        this.$message({
          message: val.message,
          type: "error",
          offset: 100,
        });
      } else if (val.data == 0) {
        this.$message({
          message: this.$t("ContentManage.DocumentManagement.SameDoc"),
          type: "error",
          offset: 100,
        });
      } else {
        this.flag = 1;
        document.styleSheets[0].deleteRule(0);
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.getDataList();
      }
    },
    // 编辑文件夹
    editFolderSave(val) {
      if (val.code == 786) {
        this.$message({
          message: this.$t("ContentManage.DocumentManagement.SameDoc"),
          type: "error",
          offset: 100,
        });
      } else if (val.code == 101) {
        this.$message({
          message: val.message,
          type: "error",
          offset: 100,
        });
      } else if (val.data == 0) {
        this.$message({
          message: this.$t("ContentManage.DocumentManagement.SameDoc"),
          type: "error",
          offset: 100,
        });
      } else {
        this.flag = 1;
        document.styleSheets[0].deleteRule(0);
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.getDataList();
      }
    },
    // 转移
    async transfers(val) {
      var ids = [];
      val.forEach((item) => {
        ids.push(item.id);
      });
      this.transFrom.srcIds = ids;
      this.listIds = val; //被勾选的数据
      this.transferFlag = true;
    },
    // 树形
    async loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([
          {
            name: this.$t("ContentManage.DocumentManagement.DocumentNav"),
            id: 0,
          },
        ]);
      }
      var parma = { parentId: node.data.id ? node.data.id * 1 : 0 };
      const res = await this.$axios.post(
        "/httpServe/document/selectFile",
        parma,
        true
      );
      res.data.forEach((item) => {
        if (item.hasChild == "true") {
          item.hasChild = false;
        } else {
          item.hasChild = true;
        }
      });
      // 相同的文件夹不可以相互转移
      for (let i = 0; i < res.data.length; i++) {
        for (let j = 0; j < this.listIds.length; j++) {
          if (res.data[i].id == this.listIds[j].id) {
            res.data.splice(i, 1);
            i--;
            break;
          }
        }
      }
      resolve(res.data);
    },
    // 转移transfer
    async transFile() {
      // console.log(this.parent,this.transFrom.destId,'转移目的文件夹123');
      if (this.parent == this.transFrom.destId) {
        this.$message({
          message: this.$t("ContentManage.DocumentManagement.TransferPurpose"),
          type: "error",
          offset: 100,
        });
        return false;
      } else {
        var parma = {
          ids: this.transFrom.srcIds,
          destId: this.transFrom.destId,
          coverType: this.transFrom.coverType, //如有同名文件：
        };
        const res = await this.$axios.post(
          "/httpServe/document/transfer",
          parma,
          true
        );
        if (res.code == 200) {
          this.$message({
            message: this.$t(
              "ContentManage.DocumentManagement.TransferSuccess"
            ),
            type: "success",
            offset: 100,
          });
          this.searchData.currentPage = 1;
          this.getDataList();
          this.$refs.tableData.clearSelection();
          this.transferFlag = false;
        } else {
          this.$message({
            message: res.message,
            type: "error",
            offset: 100,
          });
        }
      }
    },
    // 分配
    distribution(row) {
      let newData = [];
      newData.push({
        value: 4,
        idList: [row.id],
        parentId: this.searchData.parentId,
      });
      //判断一级 分配管理 名称是否能找到
      let arrName = this.$store.state.selectList.find(
        (item) => item.name == "Delivery"
      );
      if (arrName?.name) {
        arrName.children.forEach((item, index) => {
          if (item.name.indexOf("Delivery") != -1) {
            this.$router.push({
              name: "Delivery",
            });
          }
        });
        sessionStorage.setItem("complianceList", JSON.stringify(newData));
      }
    },
    // 多个分配
    distribution1() {
      let data = [];
      this.delarr.forEach((item, index) => {
        data.push(item.id);
      });
      let newData = [];
      newData.push({
        value: 4,
        idList: data,
        parentId: this.searchData.parentId,
      });
      //判断一级 分配管理 名称是否能找到
      let arrName = this.$store.state.selectList.find(
        (item) => item.name == "Delivery"
      );
      if (arrName?.name) {
        arrName.children.forEach((item, index) => {
          if (item.name.indexOf("Delivery") != -1) {
            this.$router.push({
              name: "Delivery",
            });
          }
        });
        sessionStorage.setItem("complianceList", JSON.stringify(newData));
      }
    },
    //排序
    handleSortChange(column) {
      console.log(column.prop, column.order);
      if (column.prop == "lastUpdateTime") {
        if (column.order == "ascending") {
          this.searchData.orderRule = "ASC";
        } else {
          this.searchData.orderRule = "DESC";
        }
        this.searchData.orderColume = "d.isFolder ASC, d.lastUpdateTime";
      } else if (column.prop == "name") {
        if (column.order == "ascending") {
          this.searchData.orderRule = "ASC";
        } else {
          this.searchData.orderRule = "DESC";
        }
        this.searchData.orderColume = "d.isFolder ASC, d.name";
      } else if (column.prop == "groupId") {
        if (column.order == "ascending") {
          this.searchData.orderRule = "ASC";
        } else {
          this.searchData.orderRule = "DESC";
        }
        this.searchData.orderColume = "d.isFolder ASC, d.groupId";
      } else if (column.prop == "docType") {
        if (column.order == "ascending") {
          this.searchData.orderRule = "ASC";
        } else {
          this.searchData.orderRule = "DESC";
        }
        this.searchData.orderColume = "d.isFolder ASC, d.docType";
      } else if (column.prop == "docSize") {
        if (column.order == "ascending") {
          this.searchData.orderRule = "ASC";
        } else {
          this.searchData.orderRule = "DESC";
        }
        this.searchData.orderColume = "d.isFolder ASC, d.docSize";
      } else if (column.prop == "categoryFullName") {
        if (column.order == "ascending") {
          this.searchData.orderRule = "ASC";
        } else {
          this.searchData.orderRule = "DESC";
        }
        this.searchData.orderColume = "d.isFolder ASC, dc.categoryFullName";
      } else if (column.prop == "validType") {
        if (column.order == "ascending") {
          this.searchData.orderRule = "ASC";
        } else {
          this.searchData.orderRule = "DESC";
        }
        this.searchData.orderColume = "d.isFolder ASC, d.validType";
      } else if (column.prop == "userId") {
        if (column.order == "ascending") {
          this.searchData.orderRule = "ASC";
        } else {
          this.searchData.orderRule = "DESC";
        }
        this.searchData.orderColume = "d.isFolder ASC, d.userId";
      } else if (column.prop == "groupFullName") {
        if (column.order == "ascending") {
          this.searchData.orderRule = "ASC";
        } else {
          this.searchData.orderRule = "DESC";
        }
        this.searchData.orderColume = "d.isFolder ASC, groupFullName";
      }
      this.getDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
.nameimg {
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.zTreeSelect {
  width: 260px;
  height: 250px;
  overflow-x: auto;
  border: 1px solid #ddd;
  padding-left: 6px;
  margin: 20px 55px 10px 55px;
}

.footertrans {
  margin: 10px 0 0 220px;
}

.btn-determine {
  margin-right: 4px;
}

::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #ffe6b0;
}

::v-deep .el-table__row > td {
  border: none;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table th.is-leaf {
  border: none;
}
// 面包屑hover效果
::v-deep .el-breadcrumb__inner {
  color: #d38a08;
  cursor: pointer;
}
</style>
