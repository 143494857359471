<template>
  <div class="tab">
    <el-input v-model="input" size="small" style="
        width: 200px;
        margin-top: 2px;
        position: absolute;
        margin-left: 600px;
        z-index: 999;
      " :placeholder="$t('AppManage.ApplicationLibrary.DeviceNamePlaceholder')" @keyup.enter.native="searchDev">
      <i style="cursor: pointer" class="el-icon-search el-input__icon" slot="suffix" @click="searchDev">
      </i>
    </el-input>
    <el-tabs v-model="activeName" type="card" style="position: relative;z-index: 1;" @tab-click="handleLoad">
      <el-tab-pane :label="$t('public.HaveRead')" name="1">

        <el-table size="mini" stripe class="sort_table" :data="readDevice" style="width: 820px; margin: 5px 10px 0 10px; overflow-y: auto" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }">
          <el-table-column :label="$t('public.DeviceName')" width="205" prop="deviceName" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.deviceName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Username')" width="205" prop="loginName" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.loginName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Name')" prop="loginName" width="205" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.loginName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Section')" prop="groupFullName" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">
                {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-left: 10px">
          <pagination :page="searchData.currentPage" :limit="searchData.pageSize" :total="readTotal" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList"></pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('public.Assigned')" name="2">
        <el-table :data="visibleDevice" size="mini" stripe class="sort_table" style="width: 820px; margin: 5px 10px 0 10px; overflow-y: auto" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :default-sort="{ prop: 'deviceName', order: 'descending' }">
          <el-table-column prop="deviceName" :label="$t('public.DeviceName')" width="205" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.deviceName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Username')" width="205" prop="loginName" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.loginName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Name')" prop="userName" width="205" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.userName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Section')" prop="groupFullName" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">
                {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-left: 10px">
          <pagination :page="currentPage" :limit="pageSize" :total="deliveryTotal" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList1"></pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import pagination from '@/components/pagination/page'
export default {
  components: {
    pagination
  },
  props: {
    dictData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      groupFullNameShowByIndex: '', //用户部门截取的下标
      input: '',
      activeName: '1',
      currentPage: 1,
      pageSize: 10,
      deliveryTotal: 0,
      readTotal: 0,
      readDevice: [],
      visibleDevice: [],
      searchData: {
        currentPage: 1,
        pageSize: 10
      },
    }
  },
  created() {
    if (this.dictData) {
      this.activeName = '1'
    }
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    this.nameDetail()
    this.getReadDevice()
  },
  methods: {
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.searchData.currentPage = page
      this.searchData.pageSize = limit
      // 调用查询方法
      this.getReadDevice()
    },
    handleParentGetList1(page, limit) {
      console.log(page, limit)
      this.currentPage = page
      this.pageSize = limit
      // 调用查询方法
      this.nameDetail()
    },
    // 分配
    async nameDetail() {
      var parma = {
        id: this.dictData.tabID,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        deviceName: this.input
      }
      const res = await this.$axios.post(
        '/httpServe/document/deliveryDevice',
        parma,
        true
      )
      this.visibleDevice = res.data.content
      this.deliveryTotal = res.data.total
    },
    // 阅读
    async getReadDevice() {
      var parma = {
        id: this.dictData.tabID,
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        deviceName: this.input
      }
      const res = await this.$axios.post(
        '/httpServe/document/readDevice',
        parma,
        true
      )
      this.readDevice = res.data.content
      this.readTotal = res.data.total
    },
    // 弹框查询
    searchDev() {
      if (this.activeName == '1') {
        this.getReadDevice()
      } else {
        this.nameDetail()
      }
    },
    // tab切换
    handleLoad(data) {
      // console.log(data.name,"tab切换tab切换");
      let name = data.name;
      if (name == 1) {
        this.input = '';
        this.getReadDevice();
      } else if (name == 2) {
        this.input = '';
        this.nameDetail()
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.tab {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
  height: 455px;
  position: relative;
  overflow: auto;
}

::v-deep .el-dialog__body {
  padding: 0 !important;
}

::v-deep .el-table__empty-text {
  line-height: 60px;
  width: 55%;
  color: #909399;
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: hidden;
}

::v-deep .el-table__row > td {
  border: none;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
