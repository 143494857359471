<template>
  <div>
    <el-form :model="docData" :rules="rules" ref="docData" label-width="180px" class="demo-ruleForm"
      style="margin-top: 10px; ">
      <el-form-item :label="$t('ContentManage.DocumentManagement.NewDoc')+'：'" prop="name">
        <el-input size="small" disabled v-model="docData.name" style="width: 250px; margin-right: 10px"></el-input>
        <el-upload size="small" :action="'/httpServe/sysFile/fileUp'" :auto-upload="false" :show-file-list="false"
          class="el-button uploadBox" style="padding: 0" :on-success="handleSuccess" :on-error="handleError"
          :on-change="changeUpload" multiple accept="txt, xls, docx, doc, xlsx, jpg, png, pdf, ppt, pptx, jpeg">
          <!-- <el-button size="small" type="warning" icon="el-icon-view">
            浏览
          </el-button> -->
          <el-button size="small" style="
              width: 80px;
              position: relative;
              background: #e5981e;
              border: none;
            ">
            <span style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              ">
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{$t('ContentManage.DocumentManagement.Browse')}}</span>
          </el-button>
        </el-upload>
        <el-progress v-if="flagStatus" :percentage="percentage" :color="customColorMethod" style="width: 250px"></el-progress>
      </el-form-item>
      <el-form-item :label="$t('ContentManage.DocumentManagement.CategoryFullName')+'：'" prop="docCategoryId">
        <el-select style="width: 100%; width: 250px" size="small" v-model="docData.docCategoryId" :placeholder="$t('ContentManage.DocumentManagement.SelectPlaceholder')">
          <el-option v-for="item in options1" :key="item.id" :label="item.categoryFullName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('ContentManage.DocumentManagement.DeleteOnReaded')+'：'" prop="isBurnAfterRead">
        <el-select style="width: 100%; width: 250px" size="small" v-model="docData.isBurnAfterRead" :placeholder="$t('ContentManage.DocumentManagement.SelectPlaceholder')">
          <el-option v-for="item in options2" :key="item.value" :label="$t(`ContentManage.DocumentManagement.isBurnAfterRead.${item.label}`)" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('ContentManage.DocumentManagement.Indate')+'：'">
        <el-radio v-model="docData.validType" value="1" label="1" @change="date = false">{{$t('ContentManage.DocumentManagement.PermanentlyValid')}}</el-radio>
        <el-radio v-model="docData.validType" value="2" label="2" @change="showDate">{{$t('ContentManage.DocumentManagement.Custom')}}</el-radio>
        <div v-if="date == true">
          <el-date-picker :clearable="false" style="width: 150px" v-model="docData.startTime" type="date" :placeholder="$t('ContentManage.DocumentManagement.SelectAStartTime')"
            size="small" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
          </el-date-picker>
          <span style="line-height: 30px; color: #dcdfe6">&nbsp;~&nbsp;</span>
          <el-date-picker :clearable="false" style="width: 150px" v-model="docData.endTime" type="date" :placeholder="$t('ContentManage.DocumentManagement.SelectAEndTime')" size="small"
            value-format="yyyy-MM-dd" :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item :label="$t('ContentManage.DocumentManagement.DocIntro')+'：'">
        <el-input type="textarea" v-model="docData.memo" :rows="4" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" :type="saveType" :disabled="disabledBtn" @click="submitForm('docData')">{{$t('public.Save')}}
        </el-button>
      </el-form-item>
    </el-form>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { dialogInfo },
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      flagStatus: false,
      percentage: 0,
      flag: 2,
      titleInfo: this.$t('public.SuccessfullySaved'),
      visibaelFlag: false,
      date: false,
      startTime: "",
      endTime: "",
      pickerOptions: {
        disabledDate(time) {
          return Date.now() - 24 * 60 * 60 * 1000 > time.getTime();
        },
      },
      disabledBtn:false,
      saveType:'primary',
      docData: {
        name: "",
        docType: "",
        docSize: "",
        docCategoryId: "",
        isBurnAfterRead: "1",
        validType: "1",
        startTime: "",
        endTime: "",
        memo: "",
        path: "",
        isFolder: 2,
        parentId: "0",
      },
      rules: {
        name: [{ required: true, message: this.$t('ContentManage.DocumentManagement.Rules.name'), trigger: "blur" }],
        docCategoryId: [
          { required: true, message: this.$t('ContentManage.DocumentManagement.Rules.docCategoryId'), trigger: "blur" },
        ],
      },
      options1: [],
      options2: [
        { label: "是", value: "1" },
        { label: "否", value: "0" },
      ],
    };
  },
  computed: {},
  created() { },
  mounted() {
    this.documentCategory();
  },
  methods: {
    handleClose() {
      this.visibaelFlag = false;
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return '#909399'
      } else if (percentage < 70) {
        return '#e6a23c'
      } else {
        return '#67c23a'
      }
    },
    //文件上传
    async changeUpload(file) {
      this.percentage = 0;
      this.flagStatus = true;
      this.disabledBtn = true;
      console.log(file, "file");
      if (file.size / (1024 * 1024) > 500) {
        this.flagStatus = false;
        //   限制文件大小
        this.$message({
          message: this.$t('ContentManage.DocumentManagement.SizeLimit'),
          type: "error",
          offset: 100,
        });
        return false;
      }
      let fileName = file.name;
      let suffixArray = ["txt", "xls", "docx", "doc", "xlsx", "jpg", "png", "pdf", "ppt", "pptx", "jpeg"]; //限制的文件类型，根据情况自己定义
      let docName = file.name.substring(0, fileName.lastIndexOf("."));
      this.docData.name = file.name; //获取文件名
      let suffix = file.name?.substring(fileName.lastIndexOf(".") + 1); //获取文件后缀名
      this.docData.docType = suffix;
      this.docData.docSize = String(file.size); //上传文件大小
      if (suffixArray.indexOf(suffix) === -1) {
        this.flagStatus = false;
        this.$message({
          message: this.$t('ContentManage.DocumentManagement.ErrorFile'),
          type: "error",
          duration: 2000,
          offset: 100,
        });
        this.docData.name = "";
        return false;
      } else {
        const form = new FormData();
        form.append("files", file.raw);
        form.append("type", "protected");
        form.append("fileType", suffix);
        form.append("moduleName", "document");
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
        if(res.data.code == 200){
            res.data.data.forEach((item) => {
            this.docData.path = item;
              this.disabledBtn = false
              this.saveType = 'primary'
              this.$message({
                message: this.$t('ContentManage.DocumentManagement.UploadSuccess'),
                type: 'success',
                offset: 100
              });
          });
          this.flagStatus = false;
        }else if(res.data.code == 500){
            this.disabledBtn = true
            this.saveType = 'info'
            this.$message({
              message: this.$t('ContentManage.DocumentManagement.UploadError'),
              type: "error",
              offset: 100,
            });
            this.flagStatus = false;
        }
      }
    },
    updateProgress(e) {
       //e为回调回来的参数 通过进行和total的值来进行进度
       this.percentage = parseInt((e.loaded / e.total) * 100)
    },
    handleSuccess(response, file, fileList) {
      this.$refs.docData.clearFiles();
      this.$notify({
        title: this.$t('ContentManage.DocumentManagement.UploadSuccess'),
        type: "success",
        duration: 2500,
      });
    },
    // 监听上传失败
    handleError(e, file, fileList) {
      const msg = JSON.parse(e.message);
      this.$notify({
        title: msg.message,
        type: "error",
        duration: 2500,
      });
    },
    //新增保存按钮
    submitForm() {
      this.$refs.docData.validate(async (valid) => {
        this.disabledBtn = true;
        if (valid) {
          if (this.docData.validType == 1) {
            this.docData.startTime = null;
            this.docData.endTime = null;
          } else {
            if (
              this.docData.startTime == null ||
              this.docData.endTime == null
            ) {
              this.$message({
                message: this.$t('ContentManage.DocumentManagement.EmptyTime'),
                type: "error",
                offset: 100,
              });
              return false;
            }
          }
          if (this.docData.startTime > this.docData.endTime) {
            this.$message({
              message: this.$t('ContentManage.DocumentManagement.GreaterThan'),
              type: "error",
              offset: 100,
            });
            return false;
          }
          const parma = {
            name: this.docData.name,
            docType: this.docData.docType,
            docSize: this.docData.docSize,
            docCategoryId: this.docData.docCategoryId,
            isBurnAfterRead: this.docData.isBurnAfterRead,
            validType: Number(this.docData.validType),
            startTime: this.docData.startTime,
            endTime: this.docData.endTime,
            memo: this.docData.memo,
            path: this.docData.path,
            isFolder: this.docData.isFolder,
            parentId: this.dictData.parentId,
          };
          const res = await this.$axios.post(
            "/httpServe/document/save",
            parma,
            true
          );
          if(res.data == 1){
            this.disabledBtn = false;
          }
          this.flag = 1;
          this.$emit("addSaveDoc", res);
        } else {
          console.log("error");
        }
      });
    },
    async documentCategory() {
      var parma = {
        currentPage: 1,
        pageSize: 1000,
        searchName: "",
      };
      const res = await this.$axios.post(
        "/httpServe/documentCategory/getDataInfo",
        parma,
        true
      );
      this.options1 = res.data.content;
    },
    showDate() {
      this.date = true;
      this.docData.endTime = this.$moment()
        .add("days", 30)
        .format("YYYY-MM-DD");
      this.docData.startTime = this.$moment()
        .subtract("days")
        .format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
.uploadBox {
  border: 0px !important;
}
</style>
